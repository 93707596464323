<template>
  <dog-dialog
    ref="dogDialog"
    width="600px"
    :title="typeOptions[type].title"
    @loading-confirm="confirm"
    @closed="closed"
  >
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
      :column-num="2"
      :col-rule="(item) => item.col || 12"
    ></dog-form>
  </dog-dialog>
</template>

<script>
  import resourceService from '@/api/service/resource.js';
  import iconSelect from '@/components/iconSelect.vue';

  export default {
    name: 'addResourceDialog',
    data() {
      return {
        type: 'add',
        typeOptions: {
          add: {
            title: '添加根菜单',
            requestUrl: 'addResource'
          },
          edit: {
            title: '编辑菜单',
            requestUrl: 'editResource'
          },
          addSub: {
            title: '添加子菜单',
            requestUrl: 'addResource'
          }
        },
        formData: {
          path: '',
          component: '',
          redirect: ''
        },
        parentId: null
      };
    },
    computed: {
      formOptions() {
        const path = {
          type: 'custom',
          formItem: {
            label: '路由地址',
            prop: 'path',
            rules: [this.$formRules.required('请输入路由地址')]
          },
          render: (h, { props: { cur } }) => {
            return (
              <dog-input
                placeholder='请输入路由地址'
                value={cur}
                on-input={(val) => (this.formData.path = val)}>
                <template slot='prepend'>{this.formData.parentPath}</template>
              </dog-input>
            );
          },
          col: 24
        };
        const component = {
          type: 'custom',
          formItem: {
            label: '组件路径',
            prop: 'component',
            rules: [this.$formRules.required('请输入组件路径')]
          },
          render: (h, { props: { cur } }) => {
            return (
              <dog-input
                placeholder='请输入组件路径'
                value={cur}
                on-input={(val) => (this.formData.component = val)}>
                <template slot='prepend'>
                  {this.formData.parentComponent}
                </template>
              </dog-input>
            );
          },
          col: 24
        };
        const redirect = {
          type: 'custom',
          formItem: {
            label: '重定向地址',
            prop: 'redirect',
            rules: [this.$formRules.required('请输入重定向地址')]
          },
          render: (h, { props: { cur } }) => {
            return (
              <dog-input
                placeholder='请输入重定向地址'
                value={cur}
                on-input={(val) => (this.formData.redirect = val)}>
                <template slot='prepend'>
                  {this.formData.parentRedirect}
                </template>
              </dog-input>
            );
          },
          col: 24
        };
        const chain = {
          type: 'input',
          formItem: {
            label: '外链',
            prop: 'chain',
            rules: [this.$formRules.required('请输入外链')]
          },
          col: 24
        };
        const sort = {
          type: 'el-input-number',
          formItem: {
            label: '排序',
            prop: 'sort',
            rules: [this.$formRules.required('请输入排序')]
          },
          attrs: {
            default: 1,
            min: 1,
            stepStrictly: true
          }
        };
        const blank = {
          type: 'switch',
          formItem: {
            label: '新窗口打开',
            prop: 'blank',
            rules: []
          }
        };
        const enable = {
          type: 'switch',
          formItem: {
            label: '是否启用',
            prop: 'enable',
            rules: []
          },
          attrs: {
            default: true
          }
        };
        const visible = {
          type: 'switch',
          formItem: {
            label: '是否可见',
            prop: 'visible',
            rules: []
          },
          attrs: {
            default: true
          }
        };
        const homePath = {
          type: 'switch',
          formItem: {
            label: '设为首页',
            prop: 'homePath',
            rules: []
          },
          attrs: {
            default: false
          }
        };
        const dynamicOptions = {
          folder: [path, redirect, sort, enable, visible],
          menu: [path, component, sort, blank, enable, visible, homePath],
          outerChain: [chain, sort, enable, visible]
        };
        const options = this.formData.type
          ? dynamicOptions[this.formData.type]
          : [];
        return [
          {
            type: 'input',
            formItem: {
              label: '菜单名称',
              prop: 'title',
              rules: [this.$formRules.required('请输入菜单名称')]
            }
          },
          {
            type: 'custom',
            formItem: {
              label: '菜单图标',
              prop: 'icon',
              rules: []
            },
            component: iconSelect
          },
          {
            type: 'input',
            formItem: {
              label: '菜单标识',
              prop: 'name',
              rules: [this.$formRules.required('请输入菜单标识')]
            }
          },
          {
            type: 'select',
            formItem: {
              label: '菜单类型',
              prop: 'type',
              rules: [this.$formRules.required('请选择类型')]
            },
            attrs: {
              service: this.$enumService('resourceType'),
              clearable: false
            }
          },
          ...options
        ];
      }
    },
    watch: {
      'formData.path'(val) {
        this.formData.parentRedirect = this.formData.parentPath + val + '/';
      }
    },
    methods: {
      openFrame(type = 'add', id) {
        this.type = type;
        this.$refs.dogDialog.open();
        if (id && type !== 'add') {
          this.parentId = id;
          resourceService.getResourceDetail({ id }).then((res) => {
            if (type === 'edit') {
              this.formData = res;
              const path = this.getParentPath(res.path);
              this.formData.path = path.path;
              this.formData.parentPath = path.parentPath;
              const component = this.getParentPath(res.component);
              this.formData.component = component.path;
              this.formData.parentComponent = component.parentPath;
              const redirect = this.getParentPath(res.redirect);
              this.formData.redirect = redirect.path;
              this.formData.parentRedirect = redirect.parentPath;
              this.formData.blank = this.$methods.stringNumToBoolean(res.blank);
              this.formData.enable = this.$methods.stringNumToBoolean(
                res.enable
              );
              this.formData.visible = this.$methods.stringNumToBoolean(
                res.visible
              );
              this.formData.homePath = this.$methods.stringNumToBoolean(
                res.homePath
              );
            } else if (type === 'addSub') {
              this.formData.parentPath = res.path + '/';
              this.formData.parentComponent = res.component
                ? res.component + '/'
                : '/';
              this.formData.parentRedirect = res.redirect
                ? res.redirect + '/'
                : '/';
              this.$forceUpdate();
            }
          });
        } else {
          this.formData.parentPath = '/';
          this.formData.parentComponent = '/';
          this.formData.parentRedirect = '/';
        }
      },
      getParentPath(path) {
        if (!path) {
          return {
            path: '',
            parentPath: '/'
          };
        }
        const pathArr = path.split('/');
        const parentPathArr = pathArr.filter((item, index) => {
          return index !== pathArr.length - 1;
        });
        return {
          path: pathArr[pathArr.length - 1],
          parentPath: parentPathArr.join('/') + '/'
        };
      },
      confirm(done) {
        this.$refs.dogForm
          .validate()
          .then(() => {
            const params = {
              ...this.formData,
              parentId: this.parentId,
              path: this.formData.parentPath + this.formData.path,
              component:
                this.formData.parentComponent + this.formData.component,
              redirect: this.formData.parentRedirect + this.formData.redirect,
              blank: this.$methods.booleanToStringNum(this.formData.blank),
              enable: this.$methods.booleanToStringNum(this.formData.enable),
              visible: this.$methods.booleanToStringNum(this.formData.visible),
              homePath: this.$methods.booleanToStringNum(this.formData.homePath)
            };
            return resourceService[this.typeOptions[this.type].requestUrl](
              params
            );
          })
          .then(() => {
            this.$message.success('保存成功！');
            done();
            this.$emit('confirm');
            this.close();
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.type = 'add';
        this.formData = {};
        this.parentId = null;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
