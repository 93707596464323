<template>
  <dog-dialog
    ref="dogDialog"
    width="600px"
    :title="resourceTitle"
    @closed="closed"
    :has-footer="false"
  >
    <div class="resourceBtnDialog">
      <dog-table
        ref="dogTable"
        id="8dad7b87-38cd-49ec-b80a-23ffe697d24d"
        :columns="tableColumns"
        :service="getList"
        :has-paging="false"
      >
        <permission-button slot="btn" :config="btnConfig"> </permission-button>
      </dog-table>
      <add-resource-btn-dialog
        ref="addResourceBtnDialog"
        @confirm="searchData"
      ></add-resource-btn-dialog>
    </div>
  </dog-dialog>
</template>

<script>
  import resourceService from '@/api/service/resource';
  import addResourceBtnDialog from './addResourceBtnDialog.vue';

  export default {
    name: 'resourceBtnDialog',
    components: { addResourceBtnDialog },
    data() {
      return {
        tableColumns: [
          {
            prop: 'operate',
            label: '操作',
            width: 110,
            fixed: 'right',
            render: (h, { props: { row } }) => (
              <permission-element
                config={[
                  {
                    code: 'editResourceBtn',
                    callback: () =>
                      this.$refs.addResourceBtnDialog.openFrame({
                        id: row.id,
                        resourceId: this.resourceId
                      })
                  },
                  {
                    code: 'deleteResourceBtn',
                    buttonType: 'danger',
                    callback: () => this.deleteResourceBtns(row.id)
                  }
                ]}></permission-element>
            )
          },
          { prop: 'name', label: '按钮名称', width: 100 },
          {
            prop: 'icon',
            label: '按钮图标',
            width: 80,
            render: (h, { props: { row } }) => (
              <i class={'iconfont ' + row.icon}></i>
            )
          },
          { prop: 'code', label: '按钮标识', width: 160 },
          {
            prop: 'enable_bn',
            label: '是否启用',
            width: 80,
            render: (h, { props: { row } }) => (
              <dog-switch
                value={row.enable_bn}
                onInput={(val) => this.quickOperate(row.id, val)}></dog-switch>
            )
          }
        ],
        btnConfig: [
          {
            buttonType: 'primary',
            code: 'addResourceBtn',
            callback: () =>
              this.$refs.addResourceBtnDialog.openFrame({
                resourceId: this.resourceId
              })
          }
        ],
        resourceId: null,
        resourceTitle: ''
      };
    },
    methods: {
      openFrame(id, title) {
        this.resourceId = id;
        this.resourceTitle = title;
        this.$refs.dogDialog.open();
        this.$nextTick(() => {
          this.searchData();
        });
      },
      searchData() {
        this.$refs.dogTable.search({ resourceId: this.resourceId });
      },
      getList(params) {
        return resourceService.getResourceBtnList(params);
      },
      quickOperate(id, val) {
        const transVal = val ? '1' : '0';
        this.$confirm(`是否确定${val ? '启用' : '禁用'}所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          resourceService
            .quickOperateResourceBtn({ id, enable: transVal })
            .then(this.searchData);
        });
      },
      deleteResourceBtns(id) {
        this.$confirm(`是否确定删除所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          resourceService
            .deleteResourceBtns({ ids: [id] })
            .then(this.searchData);
        });
      },
      closed() {
        this.resourceId = null;
        this.resourceTitle = '';
      }
    }
  };
</script>

<style lang="scss" scoped>
  .resourceBtnDialog {
    height: 550px;
  }
</style>
