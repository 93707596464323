<template>
  <dog-dialog
    ref="dogDialog"
    width="400px"
    :title="isEdit ? '编辑操作按钮' : '新增操作按钮'"
    @loading-confirm="confirm"
    @closed="closed"
  >
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
    ></dog-form>
  </dog-dialog>
</template>

<script>
  import resourceService from '@/api/service/resource.js';
  import iconSelect from '@/components/iconSelect.vue';

  export default {
    name: 'addResourceBtnDialog',
    data() {
      return {
        isEdit: false,
        formData: {},
        formOptions: [
          {
            type: 'input',
            formItem: {
              label: '按钮名称',
              prop: 'name',
              rules: [this.$formRules.required('请输入按钮名称')]
            }
          },
          {
            type: 'custom',
            formItem: {
              label: '按钮图标',
              prop: 'icon',
              rules: []
            },
            component: iconSelect
          },
          {
            type: 'input',
            formItem: {
              label: '按钮标识',
              prop: 'code',
              rules: [this.$formRules.required('请输入按钮标识')]
            }
          },
          {
            type: 'switch',
            formItem: {
              label: '是否启用',
              prop: 'enable',
              rules: []
            },
            attrs: {
              default: true
            }
          }
        ],
        resourceId: null
      };
    },
    methods: {
      openFrame({ id, resourceId }) {
        this.$refs.dogDialog.open();
        this.resourceId = resourceId;
        if (id) {
          this.isEdit = true;
          resourceService.getResourceBtnDetail({ id }).then((res) => {
            this.formData = {
              ...res,
              enable: this.$methods.stringNumToBoolean(res.enable)
            };
          });
        }
      },
      confirm(done) {
        this.$refs.dogForm
          .validate()
          .then(() => {
            const params = {
              resourceId: this.resourceId,
              id: this.formData.id,
              name: this.formData.name,
              icon: this.formData.icon,
              code: this.formData.code,
              enable: this.$methods.booleanToStringNum(this.formData.enable)
            };
            return resourceService[
              this.isEdit ? 'editResourceBtn' : 'addResourceBtn'
            ](params);
          })
          .then(() => {
            this.$message.success('保存成功！');
            done();
            this.$emit('confirm');
            this.close();
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.isEdit = false;
        this.formData = {};
        this.resourceId = null;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
