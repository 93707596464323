<template>
  <div class="flex_column resourceManage">
    <dog-search
      ref="search"
      :config="searchConfig"
      @search="search"
    ></dog-search>
    <dog-table
      ref="dogTable"
      id="ffb61bb6-4d71-4392-9803-87b7412a1e2e"
      :columns="tableColumns"
      :service="getList"
      row-key="id"
      :has-paging="false"
      default-expand-all
    >
      <permission-button slot="btn" :config="btnConfig"> </permission-button>
    </dog-table>
    <resource-btn-dialog
      ref="resourceBtnDialog"
      @confirm="searchData"
    ></resource-btn-dialog>
    <add-resource-dialog
      ref="addResourceDialog"
      @confirm="searchData"
    ></add-resource-dialog>
    <import-resource-dialog
      ref="importResourceDialog"
      @confirm="searchData"
    ></import-resource-dialog>
  </div>
</template>

<script>
  import resourceService from '@/api/service/resource';
  import resourceBtnDialog from './resourceBtnDialog.vue';
  import addResourceDialog from './addResourceDialog.vue';
  import importResourceDialog from './importResourceDialog';

  export default {
    name: 'resourceManage',
    components: { resourceBtnDialog, addResourceDialog, importResourceDialog },
    data() {
      return {
        searchConfig: [
          {
            type: 'input',
            label: '菜单名称',
            key: 'title'
          },
          {
            type: 'input',
            label: '菜单标识',
            key: 'name'
          },
          {
            type: 'input',
            label: '路由地址',
            key: 'path'
          },
          {
            type: 'select',
            label: '菜单类型',
            key: 'type',
            attrs: {
              service: this.$enumService('resourceType')
            }
          },
          {
            type: 'select',
            label: '是否启用',
            key: 'enable',
            attrs: {
              service: this.$enumService('enable')
            }
          },
          {
            type: 'select',
            label: '是否可见',
            key: 'visible',
            attrs: {
              service: this.$enumService('visible')
            }
          }
        ],
        tableColumns: [
          {
            prop: 'operate',
            label: '操作',
            width: 230,
            widthGrow: false,
            render: (h, { props: { row } }) => {
              return (
                <permission-element
                  config={[
                    {
                      code: 'addResource',
                      disabled: row.modifiable !== '1',
                      callback: () =>
                        this.$refs.addResourceDialog.openFrame('addSub', row.id)
                    },
                    {
                      code: 'editResource',
                      disabled: row.modifiable !== '1',
                      callback: () =>
                        this.$refs.addResourceDialog.openFrame('edit', row.id)
                    },
                    {
                      code: 'setResourceBtn',
                      disabled:
                        row.modifiable !== '1' || row.type === 'outerChain',
                      callback: () =>
                        this.$refs.resourceBtnDialog.openFrame(
                          row.id,
                          row.title
                        )
                    },
                    {
                      code: 'deleteResource',
                      buttonType: 'danger',
                      disabled: row.modifiable !== '1',
                      callback: () => this.deleteResources(row.id)
                    }
                  ]}></permission-element>
              );
            }
          },
          { prop: 'title', label: '菜单名称', width: 130 },
          {
            prop: 'icon',
            label: '图标',
            width: 50,
            widthGrow: false,
            render: (h, { props: { row } }) => (
              <i class={'iconfont ' + row.icon}></i>
            )
          },
          { prop: 'name', label: '菜单标识', width: 160 },
          { prop: 'type_cn', label: '菜单类型', width: 80, widthGrow: false },
          { prop: 'sort', label: '排序', width: 50, widthGrow: false },
          { prop: 'path', label: '路由地址', width: 300 },
          { prop: 'component', label: '组件路径', width: 300 },
          { prop: 'redirect', label: '重定向', width: 300 },
          { prop: 'chain', label: '外链', width: 300 },
          {
            prop: 'blank_bn',
            label: '新窗口打开',
            width: 90,
            widthGrow: false,
            fixed: 'right',
            render: (h, { props: { row } }) =>
              row.type === 'menu' ? (
                <dog-switch
                  value={row.blank_bn}
                  disabled={row.modifiable !== '1'}
                  onInput={(val) =>
                    this.quickOperate(row.id, val, 'blank')
                  }></dog-switch>
              ) : null
          },
          {
            prop: 'enable_bn',
            label: '是否启用',
            width: 80,
            widthGrow: false,
            fixed: 'right',
            render: (h, { props: { row } }) => (
              <dog-switch
                value={row.enable_bn}
                disabled={row.modifiable !== '1'}
                onInput={(val) =>
                  this.quickOperate(row.id, val, 'enable')
                }></dog-switch>
            )
          },
          {
            prop: 'visible_bn',
            label: '是否可见',
            width: 80,
            widthGrow: false,
            fixed: 'right',
            render: (h, { props: { row } }) => (
              <dog-switch
                value={row.visible_bn}
                disabled={row.modifiable !== '1'}
                onInput={(val) =>
                  this.quickOperate(row.id, val, 'visible')
                }></dog-switch>
            )
          },
          {
            prop: 'homePath_bn',
            label: '设为首页',
            width: 80,
            widthGrow: false,
            fixed: 'right',
            render: (h, { props: { row } }) =>
              row.type === 'menu' ? (
                <dog-switch
                  value={row.homePath_bn}
                  onInput={(val) =>
                    this.quickOperate(row.id, val, 'homePath')
                  }></dog-switch>
              ) : null
          }
        ],
        btnConfig: [
          {
            buttonType: 'primary',
            code: 'addRootResource',
            callback: () => this.$refs.addResourceDialog.openFrame()
          },
          {
            buttonType: 'primary',
            code: 'exportResource',
            loadingCallback: this.exportResource
          },
          {
            buttonType: 'primary',
            code: 'importResource',
            callback: () => this.$refs.importResourceDialog.openFrame()
          }
        ]
      };
    },
    mounted() {
      this.searchData();
    },
    methods: {
      searchData() {
        this.$refs.search.search();
      },
      search(query) {
        this.$refs.dogTable.search(query);
      },
      getList(params) {
        return resourceService.getResourceTree(params);
      },
      quickOperate(id, val, type) {
        const transVal = val ? '1' : '0';
        const options = {
          blank: {
            1: '新窗口打开',
            0: '关闭新窗口打开'
          },
          enable: {
            1: '启用',
            0: '禁用'
          },
          visible: {
            1: '可见',
            0: '隐藏'
          },
          homePath: {
            1: '设为首页',
            0: '取消设为首页'
          }
        };
        this.$confirm(`是否确定${options[type][transVal]}所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          resourceService
            .quickOperateResource({ id, [type]: transVal })
            .then(this.searchData);
        });
      },
      deleteResources(id) {
        this.$confirm(`是否确定删除所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          resourceService.deleteResources({ ids: [id] }).then(this.searchData);
        });
      },
      exportResource(done) {
        resourceService
          .exportResource(this.$refs.dogTable.query)
          .then((res) => {
            this.$methods.fileDownload(res);
          })
          .finally(done);
      }
    }
  };
</script>

<style lang="scss" scoped></style>
