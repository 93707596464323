<template>
  <div class="iconSelect">
    <el-select
      ref="elSelect"
      placeholder=""
      value=""
      @visible-change="visibleChange"
    >
      <div class="iconSelect__prefix" slot="prefix">
        <i
          class="iconfont"
          :class="activeItem || 'ep-blank'"
          :style="{ color: !activeItem ? '#409eff' : '' }"
        ></i>
      </div>
      <div class="iconSelect__main" slot="empty">
        <div class="iconSelect__main__top">
          <el-input placeholder="关键字搜索" v-model="keyword"></el-input>
        </div>
        <div class="iconSelect__main__board">
          <dog-scroll-box ref="dogSrollBox">
            <div>
              <div
                v-for="item in iconsData"
                :key="item"
                :title="item"
                :ref="item"
                :class="[
                  'iconSelect__main__board__item',
                  { active: item === activeItem }
                ]"
                @click="clickItems(item)"
              >
                <i class="iconfont" :class="item"></i>
              </div>
            </div>
          </dog-scroll-box>
        </div>
      </div>
    </el-select>
    <i class="el-icon-error" @click="clickItems()"></i>
  </div>
</template>

<script>
  import iconService from '@/api/service/icon';

  export default {
    name: 'iconSelect',
    props: {
      cur: {
        default: null
      }
    },
    data() {
      return {
        activeItem: '',
        iconsMaps: [],
        keyword: ''
      };
    },
    computed: {
      iconsData() {
        return this.iconsMaps.filter((name) =>
          name.toLowerCase().includes(this.keyword.toLowerCase())
        );
      }
    },
    watch: {
      cur: {
        handler(val) {
          this.activeItem = val;
        },
        immediate: true
      }
    },
    mounted() {
      iconService.getIcons().then((res) => {
        this.iconsMaps = res;
      });
    },
    methods: {
      visibleChange(bool) {
        if (bool) {
          this.keyword = '';
        }
      },
      clickItems(item = '') {
        this.activeItem = item;
        this.$emit('input', item);
        this.$refs.elSelect.blur();
      }
    }
  };
</script>

<style lang="scss" scoped>
  $borderColor: #d6d6d6;
  $hoverColor: #dcdfe6;
  $activeColor: #409eff;
  @include b(iconSelect) {
    position: relative;
    display: inline-block;
    cursor: default;
    &:hover {
      .el-icon-error {
        display: block;
      }
      /deep/ .el-select__caret {
        display: none;
      }
    }
    @include e(prefix) {
      display: inline-block;
      padding: 0 10px;
      text-align: center;
      vertical-align: middle;
      box-sizing: border-box;
      i {
        width: 1.28571429em;
        font-size: 18px;
        line-height: 18px;
      }
    }
    @include e(main) {
      padding: 10px 0 10px 10px;
      @include e(top) {
        padding-right: 10px;
      }
      @include e(board) {
        width: 416px;
        height: 260px;
        margin-top: 10px;
        @include e(item) {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin: 0 10px 10px 0;
          line-height: 35px;
          text-align: center;
          vertical-align: top;
          cursor: pointer;
          border: 1px solid $borderColor;
          border-radius: 5px;
          i {
            font-size: 26px;
            vertical-align: middle;
          }
          &:hover {
            background-color: $hoverColor;
          }
          &.active {
            background-color: $activeColor;
            border-color: $activeColor;
            i {
              color: white;
            }
          }
        }
      }
    }
  }
  /deep/ .el-select {
    width: 70px;
    .el-select__caret {
      line-height: 32px;
    }
  }
  i {
    color: #606266;
  }
  .el-icon-error {
    position: absolute;
    top: 14px;
    right: 10px;
    display: none;
    color: #c0c4cc;
    &:hover {
      color: #f56c6c;
      cursor: pointer;
    }
  }
</style>
